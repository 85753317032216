//redirection to azure login page
export const redirectToLogin = () => {
    window.location.assign('/sso/api/v1/login');
};

//redirection to specific apps
export const redirectToApp = (appName: string, userId: string, authToken: string) => {
    window.location.assign(`/sso/api/v1/appLogin?user=${userId}&appName=${appName}`);
};

//redirection to login
export const redirectToSSOLogin = () => {
    const source = 'platform-sso';
    window.location.assign(`/sso/auth/login?source=${source}&postRedirectURI=${window.location}`);
};


export const isNullOrUndefined = (value: any) => value === null || value === undefined;

//env select
export const getEnvType = (environment: string): 'QA' | 'UAT' | 'DEV' | '' => {
    switch (environment.toUpperCase()) {
        case 'DEV':
            return 'DEV';
        case 'QA':
            return 'QA';
        case 'UAT':
            return 'UAT';
        default:
            return '';
    }
};

//about model cancel btn
export const CancelAboutModel = (e: any, props: { isVisibleAbout: boolean; setIsVisibleAbout: Function }) => {
    if (props.isVisibleAbout === true) {
        props.setIsVisibleAbout(false);
    }
};

//dynamic year
export const CurrentYear = new Date().getFullYear();

//get url from browser

export const current_url = window.location.href;
export const url_object = new URL(current_url);
export const domain = url_object.hostname;
export const splitDomain = domain.split('.');

//redirect to logout
export const redirectToLogOut = () => {
    window.location.assign(
        `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${current_url}`
    );
};

// help section toggle
export const HelpDropdown = (props: {
    isHelpClicked: boolean;
    setIsHelpClicked: Function;
    setShowClassAdd: Function;
}) => {
    if (props.isHelpClicked === false) {
        props.setIsHelpClicked(true);
        props.setShowClassAdd('show');
    } else {
        props.setIsHelpClicked(false);
        props.setShowClassAdd('');
    }
};

//show and hide about
export const showAboutModel = (props: { isVisibleAbout: boolean; setIsVisibleAbout: Function }) => {
    if (props.isVisibleAbout === false) {
        props.setIsVisibleAbout(true);
    }
};

//get the page cookie
export function checkCookie(cookieName: string): string {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith(cookieName + '=')) {
            return trimmedCookie.split('=')[1];
        }
    }
    return '';
}

//get env by url
export const envValue = (setEnvVal: Function) => {
    if (splitDomain?.length === 3) {
        setEnvVal('');
    } else if (splitDomain?.length === 4) {
        setEnvVal(splitDomain[0]);
    }
};
